var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-app", [
    (!_vm.isZooxEye || _vm.isZooxEye === "0") && _vm.isZooxEye !== null
      ? _c(
          "div",
          {
            staticClass: "main-layout-eye",
            staticStyle: { height: "100%", background: "#f4f4f4" },
          },
          [
            _c("z-main-layout", {
              staticClass: "zoox-main-container",
              attrs: {
                "menu-options": _vm.menuOptions,
                username: "Usuário",
                "user-email": "usuario@usuario.com",
                lang: _vm.lang,
                "lang-options": ["pt", "en"],
                "src-avatar": "",
                product: "",
              },
              on: {
                logout: _vm.onLogout,
                "change-lang": _vm.onChangeLang,
                click: _vm.onChangeRoute,
                "click:option": _vm.onChangeRoute,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "product",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "content" }, [
                          _c(
                            "div",
                            { staticClass: "content__routes" },
                            [
                              _c("z-breadcrumbs", {
                                attrs: { items: _vm.itemsBreadcrumbs },
                                on: { click: _vm.onBreadcrumbClick },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "toolbar",
                    fn: function () {
                      return [
                        _c("v-icon", { staticClass: "mx-2" }, [
                          _vm._v("$notifications_rounded"),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "content",
                    fn: function () {
                      return [_c("router-view")]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1391919853
              ),
            }),
          ],
          1
        )
      : _vm.isZooxEye !== null
      ? _c("div", [_c("router-view")], 1)
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }