<template>
  <v-app>
    <div
      class="main-layout-eye"
      style="height: 100%; background: #f4f4f4"
      v-if="(!isZooxEye || isZooxEye === '0') && isZooxEye !== null"
    >
      <z-main-layout
        :menu-options="menuOptions"
        username="Usuário"
        user-email="usuario@usuario.com"
        :lang="lang"
        :lang-options="['pt', 'en']"
        src-avatar=""
        product=""
        class="zoox-main-container"
        @logout="onLogout"
        @change-lang="onChangeLang"
        @click="onChangeRoute"
        @click:option="onChangeRoute"
      >
        <template #product>
          <div class="content">
            <!-- <div
              class="content__title"
              style="display: flex; align-items: center; gap: 0.5rem"
            >
              <h3>Data to act</h3>
              <v-icon small color="#FFC220">mdi-star</v-icon>
            </div> -->
            <div class="content__routes">
              <z-breadcrumbs
                :items="itemsBreadcrumbs"
                @click="onBreadcrumbClick"
              />
            </div>
          </div>
        </template>

        <template #toolbar>
          <v-icon class="mx-2">$notifications_rounded</v-icon>
        </template>

        <template #content>
          <router-view />
        </template>
      </z-main-layout>
    </div>
    <div v-else-if="isZooxEye !== null">
      <router-view />
    </div>
  </v-app>
</template>

<script>
// import Snackbar from "@/components/Shared/Snackbar";
import ApiClient from "@/services/ApiClient";
//import Confirm from "@/components/Shared/Confirm.vue";
import { mapActions /*mapState, mapMutations*/, mapGetters } from "vuex";

export default {
  // components: { /*Snackbar,*/ Confirm },
  data() {
    return {
      itemsBreadcrumbs: [],
      http: new ApiClient(),
      isZooxEye: null,
      breadTeste: [{ text: "Home" }, { text: "Library" }, { text: "Data" }],
      menuOptions: [
        {
          title: "Home",
          icon: "$zoox_home",
          isActive: true,
        },
        {
          title: "Home",
          icon: " $device_hub_rounded",
        },
        {
          title: "Home",
          icon: "$zoox_person",
        },
        {
          title: "Home",
          icon: "$zoox_dashboard",
        },
        {
          title: "Home",
          icon: "$zoox_central",
        },
        {
          title: "Home",
          icon: "$zoox_analytics",
        },
        {
          title: "Home",
          icon: "$zoox_megaphone",
        },
        {
          title: "Home",
          icon: "$zoox_graph",
        },
        {
          title: "Home",
          icon: "$airbytelogo",
        },
        {
          title: "Home",
          icon: "$zoox_config",
        },
        {
          title: "Home",
          icon: " $zoox_edit",
        },
      ],
      lang: "en",
    };
  },

  watch: {
    $route(to) {
      this.itemsBreadcrumbs = this.changeItemsBreadcrumb(to.meta.breadcrumb);
    },
  },

  computed: {
    ...mapGetters("app", ["isLoading"]),
  },

  async mounted() {
    this.isZooxEye = localStorage.getItem("zooxEye");

    if (this.isZooxEye === null) this.isZooxEye = false;

    this.itemsBreadcrumbs = this.changeItemsBreadcrumb(
      this.$route.meta.breadcrumb
    );
  },
  methods: {
    ...mapActions("app", ["setSnack"]),
    changeItemsBreadcrumb(item) {
      if (item && item.length > 0) {
        return item.map((el) => {
          return {
            text: this.$t(el.text),
            path: el.path,
          };
        });
      }
      return item;
    },
    onBreadcrumbClick(item) {
      this.$router.push({ path: item.path });
    },
    onLogout() {},
    onChangeRoute(r) {
      // this.$router.push({ path: r.path });
      // this.$router.push(r.path);
      this.$router.push({ name: r.name });
    },

    onChangeLang(lang) {
      this.$vuetify.lang.current = lang;

      this.lang = lang;
    },
  },
};
</script>

<style lang="scss">
.zoox-main-container {
  .v-toolbar__content {
    // border: 2px solid red;
  }
  .logo-img {
    margin-top: 5px !important;

    .v-application {
      ul {
        padding-left: 0px !important;
      }
    }
  }
  .v-breadcrumbs {
    padding: 18px 3px !important;
  }
  header {
    .zoox-descrete-select {
      min-width: 106px !important;
      @media (min-width: 1441px) {
        min-width: 126px !important;
      }
    }
  }
}
.layout-container {
  display: grid;
  height: calc(100vh - 48px);
  width: 100%;
}

.eye-content-card {
  place-self: center;
  height: 95%;
  width: 95%;
}

.eye-button-toolbar {
  position: absolute;
  font-weight: 800;
  color: var(--primary);
  font-size: 1.25rem;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.loading {
  z-index: 4;
  position: absolute;
  top: 40%;
  left: 42%;
}
.container-loading {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: var(--black-800);
  opacity: 0.2;
}
</style>
<style lang="scss" scoped>
.p1 {
  color: #1976d2;
  font-size: 13px;
  padding: 0px;
  margin: auto;
}
.link {
  color: #1976d2;
  font-size: 13px;
  text-transform: unset;
}
.heigth-modal {
  height: 50%;
}
.title-icon {
  display: flex;
  justify-content: space-between;
}
.notification-date {
  color: var(--black-300);
}
.error-detail {
  background-color: #f4f4f4;
  border-radius: 10px;
  margin: 5px 0;
  padding: 15px;
}
</style>
